import React, { useEffect, useLayoutEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import SideNav from "../components/SideNav";
import { LogoContainer, Tagline } from "../components/SideNav/styles";
import {
  Container as StyledContainer,
  MainContainer,
  MenuIcon,
} from "../styles";
import styled from "styled-components";
import Img from "../../assets/logo.svg";
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";

const PromptStyles = {
  borderTop: "4px solid #11d6f0",
  padding: "1.5rem",
  background: "#fbfbfb",
  fontSize: "1.1rem",
  lineHeight: "1.5",
  color: "#000000",
};

const buttonStyles = {
  padding: "1rem",
  border: "0",
  margin: "1rem 0.5rem",
  background: "#43adda",
  color: "#ffffff",
};

const declineButtonStyle = {
  ...buttonStyles,
  background: "rgb(232 0 0)",
};

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.4rem;
    overflow-x: hidden;
  }
  html, body {
    margin: 0;
    font-family: Poppins, arial, sans-serif;
    font-weight: 300;
    font-size: 72.5%;
    height: 100%;
  }

  ul {
    margin-bottom: 1rem;
  }

  #___gatsby, #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  #gatsby-focus-wrapper {
    display: flex;
    flex: 1;
  }

  #contactForm  {
    textarea, input {
      color: #000000 !important;

    }
  }



`;

const Layout = ({ children }) => {
  const initialWindowSize = typeof window !== "undefined" && window.innerWidth;
  const [currentWindowSize, setCurrentWindowSize] = useState(0);
  const [responsiveMenuIsActive, setResponsiveMenu] = useState(
    typeof window !== "undefined" &&
      typeof window.sessionStorage !== "undefined"
      ? window.sessionStorage.getItem("activeMenu")
      : false
  );

  function resizeHandler() {
    setCurrentWindowSize(typeof window !== "undefined" ? window.innerWidth : 0);
  }

  function sendToGA() {
    if (window) {
      window.ga("send", "pageview", location.pathname);
    }
  }

  useLayoutEffect(() => {
    if (initialWindowSize >= 1024) {
      setResponsiveMenu(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("resize", resizeHandler);
    return () => document.removeEventListener("resize", resizeHandler);
  });

  function renderSideNav() {
    return (
      <>
        <SideNav
          responsiveMenuIsActive={responsiveMenuIsActive}
          initialWindowSize={initialWindowSize}
          setResponsiveMenu={setResponsiveMenu}
        />
      </>
    );
  }

  function renderMenuButton() {
    return (
      <MobileNav>
        <LogoContainer id="mobile">
          <Link to="/">
            <Img />
          </Link>
          <Tagline id="tagline">
            <h1>Peace by Piece</h1>
            <span>CBT Therapy Glasgow</span>
          </Tagline>
        </LogoContainer>{" "}
        <MenuIcon onClick={() => setResponsiveMenu(!responsiveMenuIsActive)} />
      </MobileNav>
    );
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      {typeof window !== "undefined" && responsiveMenuIsActive
        ? renderSideNav()
        : renderMenuButton()}
      <StyledContainer>
        <MainContainer>{children}</MainContainer>
      </StyledContainer>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        style={PromptStyles}
        buttonStyle={buttonStyles}
        declineButtonStyle={declineButtonStyle}
        enableDeclineButton
        flipButtons
        onAccept={sendToGA}
      >
        <h4 style={{ fontSize: "1.4rem", paddingBottom: "0.5rem" }}>
          Your Consent
        </h4>
        <p>
          Peace By Piece uses{" "}
          <span style={{ fontWeight: "bold" }}>Google Analytics</span> to track
          user engagement and page visits. By clicking Agree, you consent to
          allowing this data to be collected.
        </p>
      </CookieConsent>
    </React.Fragment>
  );
};

const MenuButton = styled.button`
  background: none;
  border: 0;
`;

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 65px;
  z-index: 9999;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 10px #777;

  #mobile {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    margin: 0;
    svg {
      min-width: 80px;
      max-width: 80px;
    }
    #tagline {
      margin: 1rem 0 0;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: red;
`;

export default Layout;
