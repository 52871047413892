import styled from "styled-components";
import { Menu } from "@styled-icons/boxicons-regular/Menu";
import { Close } from "@styled-icons/material/Close";
import { FacebookSquare } from "@styled-icons/boxicons-logos/FacebookSquare";
import { Phone } from "@styled-icons/icomoon/Phone";
import { Home } from "@styled-icons/boxicons-solid/Home";
import { HandsHelping } from "@styled-icons/fa-solid/HandsHelping";
import { InfoCircle } from "@styled-icons/boxicons-regular/InfoCircle";
import { Chat1 } from "@styled-icons/remix-fill/Chat1";
import { Calendar } from "@styled-icons/boxicons-regular/Calendar";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";

import "./reset.css";

export const theme = {
  main: "blue",
};

export const SectionForm = styled.form`
  width: 100%;

  label {
    box-sizing: border-box;
    display: block;
    margin-bottom: 0.5rem;
  }

  input[type="text"],
  select,
  input[type="email"] {
    font-family: Poppins, arial, sans-serif;

    color: #ffffff;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 1rem;
    font-size: 1.2rem;
  }

  select {
    background: rgb(30, 36, 54) none repeat scroll 0% 0%;
  }

  textarea {
    font-family: Poppins, arial, sans-serif;
    color: #ffffff;
    box-sizing: border-box;
    min-height: 150px;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
  }

  button {
    font-family: Poppins, arial, sans-serif;
    font-size: 1.3rem;
  }

  button[type="submit"] {
    margin-top: 1rem;
    background: #5d9758;
    color: #fff;
    border: none;
    padding: 1rem;
  }
`;

export const CloseIcon = styled(Close)`
  box-sizing: content-box;
  @media (min-width: 1024px) {
    display: none;
  }
  position: absolute;
  z-index: 2;
  cursor: pointer;
  color: #222222;
  padding: 10px 10px 10px 10px;
  top: 0;
`;

export const InstagramIcon = styled(Instagram)`
  color: #ffffff;
`;

export const MenuIcon = styled(Menu)`
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 1;
  top: 2%;
  right: 2%;
  height: 25px;
  width: 25px;
  box-sizing: content-box;
  background: rgb(93, 151, 88);
  color: #fff;
  padding: 5px;
`;

export const CalendarIcon = styled(Calendar)`
  color: #ffffff;
`;

export const ChatIcon = styled(Chat1)`
  color: #ffffff;
`;

export const InfoCircleIcon = styled(InfoCircle)`
  color: #ffffff;
`;

export const HandsHelpingIcon = styled(HandsHelping)`
  color: #ffffff;
`;

export const HomeIcon = styled(Home)`
  color: #ffffff;
`;

export const FacebookIcon = styled(FacebookSquare)`
  color: #ffffff;
`;

export const PhoneIcon = styled(Phone)`
  color: #ffffff;
`;

export const Container = styled.div`
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MainContainer = styled.main`
  @media (max-width: 1024px) {
    width: 100%;
  }
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0 0 0;

  @media (max-width: 1023px) {
    padding: 8rem 0 0 0;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 4rem 0;
  justify-content: center;
`;

export const PageContent = styled.div`
  @media (max-width: 1024px) {
    input {
      color: #ffffff;
    }
  }
  padding: 2rem 0;
  background: ${(props) => props.background}
  flex-direction: ${(props) => props.direction};

  h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: bold;
  }
`;

export const Section = styled.div`
  margin: 0 3rem 0 3rem;
  font-size: 1.4rem;
  line-height: 1.4;

  p {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 2rem;
    color: #444444;
    font-weight: 700;
  }
`;
