import React, { useEffect, useState } from "react";
import { MainNav, Nav, NavItem, NavItemLink, LogoContainer, Tagline } from "./styles";
import {
  CloseIcon,
  HomeIcon,
  CalendarIcon,
  HandsHelpingIcon,
  InfoCircleIcon,
  ChatIcon,
} from "../../styles";
import Img from "../../../assets/logo.svg";
import { Link } from "gatsby";

export default function SideNav(props) {
  function clickHandler() {
    props.setResponsiveMenu(false);
  }
  console.log(props);
  return (
    <MainNav className={props.responsiveMenuIsActive && "activeSideNav"}>
      <div className="navWrapper">
        <CloseIcon onClick={() => clickHandler()} size="30" />
        <LogoContainer responsiveMenuIsActive={props.responsiveMenuIsActive}>
          <Link to="/">
            <Img />
          </Link>
          <Tagline>
            <h1>Peace by Piece</h1>
            <span>CBT Therapy Glasgow</span>
          </Tagline>
        </LogoContainer>
        <Nav>
          <NavItem>
            <NavItemLink to="/" activeClassName="currentPage">
              <HomeIcon size="20" />
              Home
            </NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/appointment" activeClassName="currentPage">
              <CalendarIcon size="20" />
              Appointment
            </NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/services" activeClassName="currentPage">
              <HandsHelpingIcon size="20" />
              Services
            </NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/about" activeClassName="currentPage">
              <InfoCircleIcon size="20" />
              About
            </NavItemLink>
          </NavItem>
          <NavItem>
            <NavItemLink to="/contact" activeClassName="currentPage">
              <ChatIcon size="20" />
              Contact
            </NavItemLink>
          </NavItem>
        </Nav>
      </div>
    </MainNav>
  );
}
