import React, { useState, useEffect } from "react";
import { PageContent, PageHeader, Section, SectionForm } from "../../styles";
import { HomeIcon, PhoneIcon, FacebookIcon, InstagramIcon } from "../../styles";

const Footer = (props) => {
  const [preferredContactType, setPreferredContactType] = useState("");
  function renderPrefferedContactField() {
    return (
      <>
        <input
          style={{ background: "none", border: "1px solid #fff" }}
          placeholder="email"
          name="email"
          type="email"
          className="form-control"
          id="emailAddress"
          required={preferredContactType === "email"}
        ></input>
        <input
          style={{ background: "none", border: "1px solid #fff" }}
          placeholder="phone"
          name="phone"
          type="text"
          className="form-control"
          id="phone"
          required={preferredContactType === "phone"}
        ></input>
      </>
    );
  }
  return (
    <PageContent style={{ color: "#ffffff" }}>
      <div className="container">
        <div className="row">
          <div className="col col-sm-12 col-lg-4">
            <Section>
              <h3
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  margin: "0 0 1rem 0",
                }}
              >
                Location
              </h3>
              <div style={{ display: "flex", margin: "0 0 2rem 0" }}>
                <span
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    padding: "1rem",
                  }}
                >
                  <HomeIcon style={{ padding: "0 0.3rem 0 0" }} size="23" />
                  25 St James Street, PA3 2HQ
                </span>
              </div>
              <div style={{ margin: "0 0 2rem 0" }}>
                <span
                  style={{
                    background: "rgba(0,0,0,0.1)",
                    padding: "1rem",
                  }}
                >
                  {" "}
                  <PhoneIcon style={{ padding: "0 0.3rem 0 0" }} size="23" />
                  07912 185269
                </span>
              </div>
            </Section>
          </div>
          <div className="col col-sm-12 col-lg-4">
            <Section>
              <h3
                style={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  margin: "0 0 1rem 0",
                }}
              >
                Follow Peace By Piece
              </h3>
              <p>I'm on social networks. Follow me to get latest news</p>
              <a
                rel="nofollow"
                target="_blank"
                href="https://instagram.com/PeaceByPieceCBT"
              >
                <InstagramIcon size="48" />
              </a>
              <a
                rel="nofollow"
                target="_blank"
                href="https://facebook.com/PeaceByPieceCBT"
              >
                <FacebookIcon size="48" />
              </a>
            </Section>
          </div>
          {props.contactForm && (
            <div className="col col-sm-12 col-lg-4">
              <Section>
                <h3
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    margin: "0 0 1rem 0",
                  }}
                >
                  Send a message
                </h3>
                <SectionForm
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="email" value="" />
                  <input type="hidden" name="phone" value="" />

                  <p style={{ display: "none" }}>
                    <label>
                      Don’t fill this out if you’re human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <div>
                    <fieldset className="form-group">
                      <input
                        placeholder="name"
                        style={{ background: "none", border: "1px solid #fff" }}
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                      ></input>
                    </fieldset>
                    <fieldset>
                      <select
                        defaultValue="Preferred Contact Method"
                        className="form-control"
                        placeholder="Preferred method of contact:"
                        name="contactType"
                        onChange={(e) =>
                          setPreferredContactType(e.target.value)
                        }
                      >
                        <option style={{ color: "#ffffff" }} disabled={true}>
                          Preferred Contact Method
                        </option>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                      </select>
                    </fieldset>
                    <fieldset>{renderPrefferedContactField()}</fieldset>
                  </div>
                  <div>
                    <fieldset>
                      <textarea
                        placeholder="your message"
                        style={{ background: "none", border: "1px solid #fff" }}
                        name="notes"
                        className="form-control"
                        id="notes"
                        required
                      />
                    </fieldset>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      className="d-flex justify-content-end"
                    >
                      <button
                        style={{
                          cursor: "pointer",
                          background: "#5d9758",
                        }}
                        type="submit"
                      >
                        Send Enquiry
                      </button>
                    </div>
                  </div>
                </SectionForm>
              </Section>
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
};

export default Footer;
