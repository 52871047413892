import styled from "styled-components";
import { Link } from "gatsby";

export const Tagline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0 0 0.2rem 0;
    font-size: 2.2rem;
    color: #262626;
    @media (max-width: 1023px) {
      font-size: 1.8rem;
      margin: 0 0 0.5rem;
    }
  }

  span {
    font-size: 1.1rem;
    color: #262626;
  }
`;

export const LogoContainer = styled.div`
  justify-content: stretch;
  padding: 0 0 0 3rem;
  @media (max-width: 1024px) {
    display: ${(props) => props.responsiveMenuIsActive && "none !important"};
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 3rem 0;
    height: 320px;
    flex: 0;
    padding: 0;
  }
  display: flex;
  flex: 1;
  margin: 0;
  svg {
    min-width: 120px;
    max-width: 120px;
  }
`;

export const MainNav = styled.nav`
  @keyframes expand {
    from {
      width: 0px;
      opacity: 0;
    }
    to {
      width: 225px;
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    position: fixed;
    height: 100%;
  }
  position: sticky;
  top: 0;
  z-index: 1000;
  .navWrapper {
    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: flex-start;
    }
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  display: flex;
  flex-direction: row;
  background: #fafafa;
  border-bottom: 1px solid #cdcdcd;
  box-shadow: 0 0 10px #777;

  @media (max-width: 1024px) {
    top: 0;
    flex-grow: 0;
    z-index: 1;
    width: 0px;
    &.activeSideNav {
      width: 225px;
      animation-name: expand;
      animation-duration: 0.35s;
    }

    border-bottom: 1px solid #cdcdcd;
  }
`;

export const Nav = styled.ul`
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
    margin: 6rem 0 0 0;
    position: sticky;
    top: 0;
  }
  display: flex;
  list-style: none;
  padding: 0 7rem 0 0;
  margin: 2rem 0 0 0;
  top: 0;
`;

export const NavItem = styled.li`
  margin: 0 1rem;
  @media (max-width: 1024px) {
    margin: 1rem 1rem;
  }
`;

export const NavItemLink = styled(Link)`
  color: #4f4f4f;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.2rem 1rem;
  font-family: Poppins, arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  background: #f7f9fc;
  border: 1px solid #e4e4e4;
  border-radius: 10px;

  &.currentPage {
    color: #ffffff;
  }

  :hover {
    text-decoration: none;
  }

  :hover,
  &.currentPage {
    background: #43adda;
    color: #ffffff;

    svg {
      color: #ffffff;
      background: #43adda;
    }
  }

  svg {
    padding: 1rem 0.5rem 1rem 0.5rem;
    margin: 0;
    border-radius: 50%;
    color: #535353;
    box-sizing: content-box;
  }
`;
